import React from 'react';
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from "../components/AppLayout";
import RouterButton from '../components/shared/RouterButton'
import RouterLink from '../components/shared/RouterLink'
import FeatureContentPage from '../components/FeatureContentPage'
import PageInfo from '../components/PageInfo'
import Brand from '../components/shared/Brand'
import { GatsbyImage } from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { SchemaMarkupBlog } from '../components/common/SchemaMarkup'
import { useAllBlogPostsAndTotals } from '../components/ArticleData'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'

export default function Blog(props) {
	let {data} = props;
	const {productName} = useSiteMetadata();

	let {defaultBlogPostImg} = data;

	const {posts, totalBlogPosts} = useAllBlogPostsAndTotals();

	return (
		<AppLayout>
			<FeatureContentPage
				title={
					<React.Fragment><Brand/> BlogSpace</React.Fragment>
				}
				description={
					<React.Fragment>The <Brand/> time tracker blog.</React.Fragment>
				}
			>
				<PageInfo
					title={"Time Tracker Blog: " + productName + " BlogSpace"}
					description={"Find updated information about " + productName + " for your team's timesheet needs. We'll keep you updated on " + productName + " developments and also post other tips and content."}
				/>
				<SchemaMarkupBlog
					headline="LogSpace BlogSpace"
					description="The LogSpace time tracker blog, for insights on time tracking, expenses, and invoicing."
				/>

				<div className="container-fluid px-sm-5 mb-5">
					{totalBlogPosts === 0 ? (
						<React.Fragment>
							<h3>There are no blog posts.</h3>
							<p>Please check back later!</p>
						</React.Fragment>
					) : (
						<Grid container spacing={3}>
							{posts.map((post, idx) => {
								return (
									<Grid key={idx} item xs={12} md={6} lg={4}>
										<BlogPostCard
											post={post}
											defaultBlogPostImg={defaultBlogPostImg}
										/>
									</Grid>
								);
							})}
						</Grid>
					)}
				</div>
			</FeatureContentPage>
		</AppLayout>
	);
}
Blog.propTypes = {
	data: PropTypes.shape({
		defaultBlogPostImg: PropTypes.object.isRequired,
	}).isRequired,
};

const useBlogPostCardStyles = makeStyles(theme => ({
	blogPostCard: {
		height: "100%",
	},
}), {name: "BlogPostCard"});

export function BlogPostCard(props) {
	const {post, suppressPreviewText, defaultBlogPostImg, elevation, header, actions} = props;
	const classes = useBlogPostCardStyles();

	const {path, title, previewText, previewImage} = post;
	const img = previewImage || defaultBlogPostImg;
	return (
		<Card elevation={elevation} className={classes.blogPostCard}>
			{header && (<CardHeader subheader={header}/>)}
			<RouterButton
				component={CardActionArea}
				to={path}
			>
				<div>
					{img && (
						<GatsbyImage alt={title} image={img.childImageSharp.gatsbyImageData}/>
					)}
				</div>
			</RouterButton>
			<CardContent>
				<RouterLink to={path} suppressDecoration>
					<Typography gutterBottom={!suppressPreviewText} variant="h5" component="h2">{title}</Typography>
				</RouterLink>
				{!suppressPreviewText && (
					<Typography variant="body2" color="textSecondary" component="p">{previewText}</Typography>
				)}
			</CardContent>
			{actions && (<CardActions>{actions}</CardActions>)}
		</Card>
	);
}
BlogPostCard.propTypes = {
	post: PropTypes.shape({
		path: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		previewText: PropTypes.string.isRequired,
		previewImage: PropTypes.object,
	}).isRequired,
	/** true to exclude the preview text */
	suppressPreviewText: PropTypes.bool,
	defaultBlogPostImg: PropTypes.object,
	elevation: PropTypes.number,
	header: PropTypes.string,
	actions: PropTypes.arrayOf(PropTypes.node),
};

export const query = graphql`{
  defaultBlogPostImg: file(relativePath: {eq: "pages/blog-content/images/aaron-burden-xG8IQMqMITM-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 700, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
