import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import {CookieConsention} from '../page/CookieBanner'
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton'
import CardActionArea from '@material-ui/core/CardActionArea'

export default class RouterButton extends React.Component {
	constructor(props) {
		super(props);
		this.handleLink = this.handleLink.bind(this);
	}

	handleLink(evt, path, onCookieConsent) {
		let {onClick} = this.props;

		onCookieConsent();

		if (onClick) {
			onClick(evt);
		}

/*
		if (!evt.defaultPrevented) {
			if (!evt.metaKey && !evt.ctrlKey) {
				// We're not opening in a new tab
				evt.preventDefault();
				history.push(path);
			}
		}
*/
	}

	render() {
		const {to, className, component: Component = Button, disabled, color, fullWidth, size, variant, startIcon, endIcon, children, additionalProps} = this.props;
		let pathname = to;
		let search = "";
		if (to.pathname) {
			pathname = to.pathname;
			search = to.search || "";
		}

		// TODO: copied from bootstrap router button... is this note still applicable?
		// "disabled" works differently for <a/> than <button/>. Instead of saying disabled="true", we would have to add
		// a "disabled" class to the </a>. And we would want to make sure onClick did not work. Bootstrap takes care of
		// "pointer-events" with the "disabled" class, but tab focusing and pressing enter still works, which is
		// different than with buttons.
		let useButton = disabled;

		// TODO: material buttons don't have "active" ?

		let definedProps = {fullWidth, startIcon, endIcon};
		// In case this is not a "Button", but e.g. an "IconButton" that doesn't support some of the above props
		Object.entries(definedProps).forEach(e => {
			if (e[1] === undefined) {
				delete definedProps[e[0]];
			}
		});

		if (Component !== Button && Component !== IconButton && Component !== CardActionArea) {
			// Unexpected component. We need to render it with component={Link} --> a gatsby Link
			console.error("RouterButton expected to be a Button or IconButton or CardActionArea. This may cause unintended linking issues.");
		}

		return (
			<CookieConsention>
				{onCookieConsent => (
					useButton ? (
						<Component
							className={className}
							onClick={evt => { this.handleLink(evt, to, onCookieConsent); }}
							disabled={disabled}
							color={color}
							size={size}
							variant={variant}
							{...definedProps}
							{...additionalProps}
						>
							{children}
						</Component>
					) : (
						<Component
							component={Link}
							to={pathname + search}
							className={className}
							onClick={evt => { this.handleLink(evt, to, onCookieConsent); }}
							color={color}
							size={size}
							variant={variant}
							{...definedProps}
							{...additionalProps}
						>
							{children}
						</Component>
					)
				)}
			</CookieConsention>
		);
	}
}
RouterButton.propTypes = {
	/** Either an object containing a "pathname" or a string that is the pathname */
	to: PropTypes.any.isRequired,
	/** Extra CSS classes for the button */
	className: PropTypes.string,
	component: PropTypes.elementType,
	onClick: PropTypes.func,
	/** true if the button should be disabled */
	disabled: PropTypes.bool,
	color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
	fullWidth: PropTypes.bool,
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
	startIcon: PropTypes.node,
	endIcon: PropTypes.node,
	/** additional props for the Button component */
	additionalProps: PropTypes.object,
	/** The button content */
	children: PropTypes.any.isRequired,
};
